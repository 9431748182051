//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import Popfield from "./components/Popfield.vue";
import Popdatefield from "./components/Popdatefield.vue";
export default {
  components: {
    "pop-field": Popfield,
    "pop-datefield": Popdatefield,
  },
  data() {
    return {
      enteryData: {
        hhrEmpid: "", //人员编号
        hhrEmpName: "", //员工姓名
        oaPosnName: "", //OA所属岗位
        oaPosnId: "", //OA所属岗位Id
        oaDeptName: "", //OA所属部门
        oaDeptId: "", //OA所属部门id
        hhrCountry: "", //国籍
        hhrSex: "", //性别
        hhrCertificateType: "", //身份证件类型
        hhrCertificateNum: "", //身份证件号码
        hhrBirthDate: "", //出生日期
        hhrMobile: "", //联系电话
        hhrChannelBig: "", //招聘渠道大类
        hhrChannelSmall: "", //招聘渠道小类
        hhrChannelOther: "", //其他渠道分类
        hhrPernr: "", //内部推荐人
        hhrEvaluation: "", //面试评价
        hhrHealthStatus: "", //健康状况
        hhrEntryDate: "", //入职日期
        hhrFrzt: "", //法人主体
        hhrEmpClass: "", //员工类型
        hhrLevel: "", //管理层级
        hhrDeptCode: "", //入职部门
        hhrPosnCode: "", //入职岗位Code
        hhrRsClass: "", //人事子范围
        hhrIfAccommodation: "", //是否住宿
        hhrYaccount: "", //域账号
        hhrCompanyEmali: "", //公司邮箱
        hhrAuthority: "", //信息权限
        _status: "create",
        tenantId: 0,
      },
      outOB: {
        hhrDeptName: "",
      },
      saveSate: false,
    };
  },
  created() {

  },
  methods: {
    onSubmit() {
      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {},
        payload: "",
      };
      this.saveSate = true;
      this.axios.post("/boogooForm/getEmpid", postData).then((res) => {
        console.log(res);

        if (res && res.data) {
          if (res.data.status == "200") {
            let resultData = JSON.parse(res.data.payload);
            if (resultData.failed) {
              this.saveSate = false;
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: resultData.message,
                timeout: 1000
                
              });
            } else {
              this.enteryData.hhrEmpid = res.data.payload;
              this.dosave();
            }
          }
        }
      });
    },

    dosave() {
      //let postData = this.enteryData;

      let postData = {
        pathVariableMap: {
          tenantId: "0",
        },
        headerParamMap: {},
        requestParamMap: {
          reqType: "save",
          entityName: "com.boogoo.hhr.corehr.wf.domain.entity.WfOrgPerEntry",
        },
        payload: JSON.stringify([this.enteryData]),
      };  
      console.log(this.enteryData);

      this.axios.post("/boogooForm/excuteMethod", postData, { headers: {"processDefinitionKey":"PER_ENTRY_DEF","employeeNum":this.$memberInfo.hhrEmpid}}).then((res) => {
        console.log(res);

        if (res && res.data) {
          this.saveSate = false;
          if (res.data.status == "200") {
            let resultData = JSON.parse(res.data.payload);
            if (resultData.failed) {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: resultData.message,
                timeout: 1000
              });
            } else {
              this.$q.notify({
                type: "positive",
                color: "grey-8",
                textColor: "white",
                position: "center",
                message: "提交成功！",
                timeout: 1000
              });
              this.$router.replace("/oaplatform/list");
            }
          }
        }
      })
      .catch(()=> {
          this.saveSate = false;
          Toast("请求出错了");
        });
    },



    popConfirm(obj) {
      console.log(obj);
      switch (obj.key) {
        case "hhrPosnCode":
          this.outOB.hhrDeptName = obj.value.obj.hhrDeptName;
          this.enteryData.hhrDeptCode = obj.value.obj.hhrDeptCode;
          break;
      }
      this.enteryData[obj.key] = obj.value.value;
      // console.log(obj.key,this[obj.key]);
      console.log(this.enteryData);
    },
    dateConfirm(obj) {
      console.log(obj);
      this.enteryData[obj.key] = obj.value;
      console.log(this.enteryData);
    },
  },
  mounted() {},
};
