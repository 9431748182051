//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import qs from "qs";
export default {
  props: {
    popType: String,
    defaultVal: {
      type: String,
      default: "",
    },
    proLabel: {
      type: String,
      default: "出生日期",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  name: "Popfield",
  data() {
    return {
      fieldVal: "",
      rule: [],
      valPicker: false,
      minDate: new Date(1920, 0, 1),
      currentDate: new Date(),
    };
  },
  created() {
    this.initPop();
  },
  methods: {
    initPop() {
      this.rule = [{ required: this.required, message: "" }];
      this.fieldVal = this.defaultVal;
    },
    showpick() {
      if (this.disabled) {
        return;
      }
      this.valPicker = true;
    },
    pcikConfirm(value) {
      console.log(value);
      this.fieldVal = this.$moment(value).format("YYYY-MM-DD");

      this.valPicker = false;
      let popData = {
        key: this.popType,
        value: this.$moment(value).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.$emit("popconfirm", popData);
    },
  },
  mounted() {},
    watch:{
     defaultVal(val){
       this.fieldVal=(val!=""&&val!=null)?this.$moment(val).format("YYYY-MM-DD"):'';

          
     }

  },
};
